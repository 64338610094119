.container {
  padding: 30px;
  background: #ececec;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    width: 350px;
    height: auto;

    .space {
      width: 100%;
    }
  }
}