.dashboardLayout {
  height: 100%;

  .expandedSidebar {
    // we need to override element's style defined inside ant :(
    max-width: 202px !important;
    flex: 0 0 202px !important;
  }

  .appLogo {
    text-align: center;
    padding: 13px;
    font-size: 20px;
    font-weight: 700;
    .left {
      color: #722ed1;
    }
    .right {
      color: #fff;
    }
  }

  .siteLayout {
    .header {
      background: #fff;
      padding: 0;

      .trigger {
        padding: 0 16px;
        font-size: 18px;
        line-height: 64px;
        cursor: pointer;
        transition: color 0.3s;
      }

      .trigger:hover {
        color: #1890ff;
      }
    }
  }

  .content {
    background: #fff;
    margin: 24px 16px;
    padding: 24px;
    min-height: 280px;
    overflow-y: scroll;
  }
}
