.container {
  display: grid;
  // TODO uncomment when game is available
  //grid-template-columns: 200px auto 40px;
  grid-template-columns: auto 40px;
  grid-gap: 16px;
  height: 100%;
  justify-content: end;
  align-items: center;
  padding-right: 16px;
}
