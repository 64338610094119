.container {
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  .text {
    flex: 1;
    .title {
      font-weight: 600;
      font-size: 36px;
      margin-right: 16px;
    }
  }

  .createButton{
    text-align: end;
    justify-self: end;
  }
}

